@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

#marketSelector {
  top: 0;

  .modal-dialog {
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
    margin: 0;

    .modal-content {
      height: 100%;
      background-color: @Black;
      border: none;
      border-radius: 0;
    }

    .modal-header {
      border: none;
      color: @White;

      .btn-close {
        opacity: 1;
      }
    }

    .main-content {
      max-width: 1104px;
      margin: auto;
    }
  }

  .modal-body {
    padding-top: 0;
    overflow-x: auto;

    .region-box {
      width: auto;

      .region-title {
        border-bottom: 1px solid @Gray20;
        margin-bottom: @spacer7;
        text-transform: capitalize;
        padding-bottom: @spacer3;
        width: 100%;
        color: @White;
      }

      .country-box {
        min-width: 300px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        display: flex;

        .country-name {
          display: inline;
          color: @White;

          a {
            color: @White;
          }
        }

        .country-flag {
          display: inline-block;
          margin-right: @spacer2;
          width: 20px;
          height: 13.33px;
          background-color: @Background;
        }
      }

      .column-list-region {
        @media (min-width: @screen-lg-min) {
          column-count: 3;
          -webkit-column-count: 3; /* Chrome, Safari, Opera */
          -moz-column-count: 3; /* Firefox */
        }
      }
    }
  }
}
